export const environment = {
  production: false,
  TOKEN_URL: 'https://sales-backend-development.azurewebsites.net/api/v1/auth/token',
  REFRESH_URL: 'https://sales-backend-development.azurewebsites.net/api/v1/auth/refreshToken',
  BASE_URL: 'https://sales-backend-development.azurewebsites.net',
  API_URL: 'api',
  DEFAULT_LANGUAGE: 'it-IT',
  APP_NAME: 'Sales',
  ENV: 'Dev azure',
};

