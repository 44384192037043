import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogMessageService } from './core/services/dialog-message.service';
import { MessageComponent } from './core/components/message/message.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogMessage } from './core/models/dialog-message';
import { DialogDeleteConfirmService } from './core/services/dialog-delete-confirm.service';
import { DialogErrorService } from './core/services/dialog-error.service';
import { ToastService } from './core/services/toast.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DialogChangePageConfirmService } from './core/services/dialog-change-page-confirm.service';
import { DialogLogoutConfirmService } from './core/services/dialog-logout-confirm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    DialogDeleteConfirmService,
    DialogLogoutConfirmService,
    DialogChangePageConfirmService,
    DialogErrorService,
    DialogService,
    ToastService,
    DynamicDialogConfig,
    ConfirmationService,
    MessageService
  ]
})
export class AppComponent {

  constructor(
    private readonly _dialog: DialogService,
    private readonly _dialogMessage: DialogMessageService,
    private readonly _title: Title) {
    this._initialize();
  }

  public _initialize() {
    let title = `${environment.APP_NAME}`;
    if (environment.ENV != null) {
      title += ` - ${environment.ENV}`;
    }
    this._title.setTitle(title);
  }
}
