import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LanguageCulture } from '../autogenerated/model.autogenerated';

@Injectable({
  providedIn: 'root'
})
export class LanguageCultureService {

  private _currentLanguageSubject = new BehaviorSubject<LanguageCulture | null>(null);
  
  public get currentLanguage$() { return this._currentLanguageSubject; }
  public get currentLanguage() { return this._currentLanguageSubject.value; }

  constructor() { }

  setCurrentLanguageCulture(languageCulture: LanguageCulture | null) {
    this._currentLanguageSubject.next(languageCulture);
  }
}
