import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, catchError, throwError } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { LanguageCulture, Result } from '../autogenerated/model.autogenerated';
import { environment } from 'src/environments/environment';
import { LanguageCultureService } from './language-culture.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageApiService {

  private _supportedLanguages: LanguageCulture[] = [];

  public get supportedLanguages() { return this._supportedLanguages; }

  //non injettare ENUM service
  constructor(
    private readonly _translate: TranslateService,
    private readonly _languageCulture: LanguageCultureService,
    private readonly _api: ApiService,
    private readonly _user: UserService
  ) {

  }

  public initialize(userLanguage: LanguageCulture | null) {
    return this._api.LanguageCulture_List()
      .pipe(
        tap(x => this._supportedLanguages = x.items!),
        map(x => {
          if (userLanguage != null && userLanguage.languageCultureName != null) {
            return userLanguage.languageCultureName;
          } else {
            return navigator.language;
          }
        }),
        map(x => this._checkAndGetLanguageCulture(x)),
        exhaustMap(language => {
          if (userLanguage == null || language.languageCultureName != userLanguage.languageCultureName) {
            //x è la lingua da impostare all'utente
            return this._updateUserLanguage(language)
              .pipe(
                map(x => language)
              );
          } else {
            return of(null)
              .pipe(
                map(x => language)
              );
          }
        }),
        tap(language => this._setUserLanguage(language)),
        tap(language => this._translate.use(language.languageCultureName!)),
      );
  }

  public setUserLanguage(languageCulture: LanguageCulture) {
    languageCulture = this._checkAndGetLanguageCulture(languageCulture.languageCultureName!);
    this._setUserLanguage(languageCulture);
    return this._updateUserLanguage(languageCulture);
  }

  private _setUserLanguage(languageCulture: LanguageCulture) {
    this._languageCulture.setCurrentLanguageCulture(languageCulture);
    // this._translate.use(languageCulture.languageCultureName!);
  }

  private _checkAndGetLanguageCulture(languageCultureName: string) {
    let languageCulture = this._findLanguageCulture(languageCultureName);

    if (languageCulture == null) {
      console.warn(`Language - unsupported language ${languageCultureName}`);
      //la lingua da impostare non è supporta percio leggo quella di default
      languageCulture = this._findLanguageCulture(environment.DEFAULT_LANGUAGE);
    }

    console.info(`Language - setting user language to ${languageCultureName}`);

    if (languageCulture == null) {
      const errorMessage = `Language - error unable to find default language ${languageCultureName}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    return languageCulture;
  }

  private _updateUserLanguage(languageCulture: LanguageCulture) {
    let language = this._findLanguageCulture(environment.DEFAULT_LANGUAGE);
    if (this._user.user?.idUser == null) {
      this._languageCulture.setCurrentLanguageCulture(language ?? null);
      // this._translate.use(environment.DEFAULT_LANGUAGE);
      return of(new Result());
    } else {
      return this._api.User_UpdateLanguageCulture(languageCulture?.idLanguageCulture!)
        .pipe(
          catchError((err, obs) => {
            const message = `Language - Error during update language. Current language was set to default language ${environment.DEFAULT_LANGUAGE}`
            this._languageCulture.setCurrentLanguageCulture(language ?? null);
            // this._translate.use(environment.DEFAULT_LANGUAGE);
            console.error(message);
            return throwError(() => new Error(message));
          })
        );
    }
  }

  private _findLanguageCulture(languageCultureName: string) {
    return this._supportedLanguages.find(x => x.languageCultureName == languageCultureName);
  }

}
