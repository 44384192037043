import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { map } from "rxjs";
import { DialogChangePageConfirmComponent } from "../components/dialog-change-page-confirm/dialog-change-page-confirm.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class DialogChangePageConfirmService {

  constructor(
    private readonly _dialog: DialogService,
    private readonly _translate: TranslateService
    ) {
  }

  public show() {
    const ref = this._dialog.open(DialogChangePageConfirmComponent, {
      // panelClass: 'mat-dialog-container-dialog-message',
      header: this._translate.instant('Common.DeactivateTitle'),
      closeOnEscape: true,
      dismissableMask: false,
      closable: false,
      // styleClass: 'header-danger',
      width: '600px', //todo is mobile
      duplicate: false,
      baseZIndex: 100000,
      maximizable: false,
      position: 'top',
    });

    return ref.onClose
      .pipe(
        map(x => <boolean>x)
      );
  }
}
