<!-- <div class="text-white p-[10px] border-b border-b-[#dee2e6]" [ngClass]="{
      'bg-[#cfe2ff]' : message.type == messageTypeEnum.INFO,
      'bg-[#e2e3e5]' : message.type == messageTypeEnum.QUESTION,
      'bg-[#d1e7dd]' : message.type == messageTypeEnum.SUCCESS,
      'bg-[#f8d7da]' : message.type == messageTypeEnum.ERROR,
      'bg-[#fff3cd]' : message.type == messageTypeEnum.WARNING,
      'bg-[#d3d3d4]' : message.type == null}">
      <h3 [ngClass]="{
        'text-[#084298]' : message.type == messageTypeEnum.INFO,
        'text-[#41464b]' : message.type == messageTypeEnum.QUESTION,
        'text-[#0f5132]' : message.type == messageTypeEnum.SUCCESS,
        'text-[#842029]' : message.type == messageTypeEnum.ERROR,
        'text-[#664d03]' : message.type == messageTypeEnum.WARNING,
        'text-[#141619]' : message.type == null}">
        {{message.title}}
      </h3>
    </div> -->

<p class="mt-6 mb-6">{{message.message}}</p>

<p-accordion *ngIf="message?.url != null || message?.status != null || message?.details != null">
  <p-accordionTab header="Details">
    <p *ngIf="message?.url != null">Url: {{message.url}}</p>
    <p *ngIf="message?.status != null">Status: {{message.status}}</p>
    <p *ngIf="message?.details != null" class="break-words">Details: {{message.details}}</p>
  </p-accordionTab>
</p-accordion>

<div class="flex justify-end mt-6">
  <p-button icon="pi pi-check" [text]="true" label="Ok" (click)="onOkClicked()"></p-button>
</div>