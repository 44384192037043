import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { tap } from "rxjs";
import { EnumListQueryResponse } from "../autogenerated/model.autogenerated";

@Injectable({
  providedIn: 'root'
})
export class EnumsService {

  private _enums: EnumListQueryResponse | null = null;

  public get enums() { return this._enums; }

  constructor(private readonly _api: ApiService) {

  }

  public reload(){
    this.initialize().subscribe();
  }

  public initialize() {
    return this._api.Enum_List()
      .pipe(
        tap(x => this._enums = x)
      )
  }

  public getContractStateNameById(idContractState: number) {
    return this.enums?.contractStates!.find(x => x.idContractState == idContractState)?.name!;
  }

  public getContractTypeNameById(idContractType: number) {
    return this.enums?.contractTypes!.find(x => x.idContractType == idContractType)?.name!;
  }

  public getContractAgreementNameById(idContractAgreementFormat: number) {
    return this.enums?.contractAgreementFormats!.find(x => x.idContractAgreementFormat == idContractAgreementFormat)?.name!;
  }

  public getRepresentativeDepartementNameById(idRepresentativeDepartement: number) {
    return this.enums?.representativeDepartements!.find(x => x.idRepresentativeDepartement == idRepresentativeDepartement)?.name!;
  }

  public getComunicationMethodById(idCommunicationMethod: Number){
       return this.enums?.communicationMethods!.find(x => x.idCommunicationMethod == idCommunicationMethod)?.name!
  }

  public getSalesActivitiesStatusById(idSalesActivitiesStatus: Number){
    return this.enums?.salesActivitiesStatus!.find(x => x.idSalesActivitiesStatus == idSalesActivitiesStatus)?.name!
  }

  public getCommunicationDirectionById(idCommunicationDirection: Number){
    return this.enums?.communicationDirections!.find(x => x.idCommunicationDirection == idCommunicationDirection)?.name!
  }

  public getDealProcessingStateById(idDealProcessingState: Number) {
      return this.enums?.dealProcessingStates!.find(x => x.idDealProcessingState == idDealProcessingState)?.name!
  }

  // authUserRoles: AuthUserRole[] | undefined;
  // dealProcessingStates: DealProcessingState[] | undefined;
  // hotelRoomExclusiveServices: HotelRoomExclusiveService[] | undefined;
  // hotelRoomInformationSources: HotelRoomInformationSource[] | undefined;
  // hotelRoomOccupancies: HotelRoomOccupancy[] | undefined;
  // hotelRoomTypes: HotelRoomType[] | undefined;
  // contractPaymentModes: ContractPaymentMode[] | undefined;
}
