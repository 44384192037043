import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MessageComponent } from '../components/message/message.component';
import { ResponseError } from '../models/response-error';
import { DialogMessage } from '../models/dialog-message';
import { DialogMessageType } from '../models/dialog-message-type';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogErrorMessage } from '../models/dialog-error-message';

@Injectable({
  providedIn: 'root',
})
export class DialogMessageService {

  private _messages: Subject<DialogMessage>;
  private _errorMessages: Subject<DialogErrorMessage>;

  public get messages$(): Observable<DialogMessage> { return this._messages.asObservable(); }
  public get errorMessages$(): Observable<DialogErrorMessage> { return this._errorMessages.asObservable(); }

  constructor(
  ) {
    this._messages = new Subject<DialogMessage>();
    this._errorMessages = new Subject<DialogErrorMessage>();
    // this._initialize();
  }

  // public _initialize() {
  //   this.messages$.subscribe((x) => {
  //     this._dialog.open(MessageComponent, {
  //       // panelClass: 'mat-dialog-container-dialog-message',
  //       width: '70%', //todo is mobile
  //       duplicate: false,
  //       baseZIndex: 100000,
  //       maximizable: false,
  //       position: 'top',
  //       data: x,
  //     });
  //   });
  // }

  // public showMessage(title: string, message: string) {
  //   this.show(title, message, DialogMessageType.INFO);
  // }

  // public showWarning(title: string, message: string) {
  //   this.show(title, message, DialogMessageType.WARNING);
  // }

  // public showQuestion(title: string, message: string) {
  //   let msg = new DialogMessage();
  //   msg.title = title;
  //   msg.message = message;
  //   msg.type = DialogMessageType.QUESTION;

  //   let a = this._dialog.open(MessageComponent, {
  //     panelClass: 'mat-dialog-container-dialog-message',
  //     disableClose: true,
  //     autoFocus: false,
  //     position: {
  //       top: '50px'
  //     },
  //     data: msg,
  //   });

  //   return a.afterClosed()
  //     .pipe(
  //       map(x => <boolean>x)
  //     );
  // }

  // public showConfirmDelete() {
  //   return this.showQuestion("Conferma eliminazione", "Sei sicuro di voler continuare?");
  // }

  public showError(message: string) {
    this.show("Si è verificato un errore", message, DialogMessageType.ERROR);
  }

  public showFromResponseError(responseError: ResponseError) {
    let msg = new DialogErrorMessage();
    msg.message = responseError.message;
    msg.url = responseError.url;
    msg.status = responseError.status;
    msg.details = responseError.stacktrace;
    this._errorMessages.next(msg);
  }

  public show(title: string, message: string, type: DialogMessageType) {
    let msg = new DialogMessage();
    msg.title = title;
    msg.message = message;
    msg.type = type;
    this._show(msg);
  }

  private _show(dialogMessage: DialogMessage) {
    this._messages.next(dialogMessage);
  }
}
