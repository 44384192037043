import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_LAYOUT, APP_LOGIN } from './core/utils/constants';

const routes: Routes = [
  { path: '', redirectTo: APP_LAYOUT, pathMatch: 'full' },
  { path: APP_LOGIN, loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },
  { path: APP_LAYOUT, loadChildren: () => import('./features/layout/layout.module').then(m => m.LayoutModule) },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableViewTransitions: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
