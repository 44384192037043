import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { map } from "rxjs";
import { DialogDeleteConfirmComponent } from "../components/dialog-delete-confirm/dialog-delete-confirm.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ToastService {

  constructor(
    private readonly _messageService: MessageService,
    private readonly _translate: TranslateService) {
  }

  showSaveSuccess(){
    this.showSuccess(this._translate.instant('Common.Toast.Save.Title'), this._translate.instant('Common.Toast.Save.Detail'));
  }

  showSuccess(title: string, detail: string) {
    this._messageService.add({ severity: 'success', summary: title, detail: detail });
  }

  showInfo(title: string, detail: string) {
    this._messageService.add({ severity: 'info', summary: title, detail: detail });
  }

  showWarn(title: string, detail: string) {
    this._messageService.add({ severity: 'warn', summary: title, detail: detail });
  }

  showError(title: string, detail: string) {
    this._messageService.add({ severity: 'error', summary: title, detail: detail });
  }
}