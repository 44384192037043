import { Injectable } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Observable, Subject, map } from "rxjs";
import { DialogDeleteConfirmComponent } from "../components/dialog-delete-confirm/dialog-delete-confirm.component";
import { DialogService } from "primeng/dynamicdialog";

@Injectable()
export class DialogDeleteConfirmService {

  constructor(
    private readonly _dialog: DialogService) {
  }

  public show() {
    const ref = this._dialog.open(DialogDeleteConfirmComponent, {
      // panelClass: 'mat-dialog-container-dialog-message',
      header: 'Delete confirmation',
      closeOnEscape: true,
      dismissableMask: false,
      closable: false,
      // styleClass: 'header-danger',
      width: '600px', //todo is mobile
      duplicate: false,
      baseZIndex: 100000,
      maximizable: false,
      position: 'top',
    });

    return ref.onClose
      .pipe(
        map(x => <boolean>x)
      );
  }
}