import { Injectable } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Observable, Subject, map } from "rxjs";
import { DialogDeleteConfirmComponent } from "../components/dialog-delete-confirm/dialog-delete-confirm.component";
import { DialogService } from "primeng/dynamicdialog";
import { DialogErrorComponent } from "../components/dialog-error/dialog-error.component";
import { DialogErrorMessage } from "../models/dialog-error-message";
import { Result } from "../autogenerated/model.autogenerated";
import { ResponseError } from "../models/response-error";
import { DialogMessageService } from "./dialog-message.service";

@Injectable()
export class DialogErrorService {

  constructor(
    private readonly _dialog: DialogService,
    private readonly _dialogMessage: DialogMessageService) {
      this._initialize();
  }

  private _initialize() {
    this._dialogMessage.errorMessages$
      .subscribe(x => {
        this.show(x);
      })
  }

  public showFromResult(result: Result) {
    return this.show({
      message: result.message!,
      details: result.exception?.message + (result.exception?.stackTrace ? '<br/>' + result.exception?.stackTrace : ''),
      status: null,
      url: null
    })
  }

  public show(dialogErrorMessage: DialogErrorMessage) {
    const ref = this._dialog.open(DialogErrorComponent, {
      // panelClass: 'mat-dialog-container-dialog-message',
      header: 'An error occurred',
      closeOnEscape: true,
      dismissableMask: false,
      closable: false,
      styleClass: 'header-danger',
      width: '600px', //todo is mobile
      duplicate: false,
      baseZIndex: 100000,
      maximizable: false,
      position: 'top',
      data: dialogErrorMessage
    });

    return ref.onClose
      .pipe(
        map(x => <boolean>x)
      );
  }
}