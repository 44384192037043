export class DialogErrorMessage{
    public message: string | null;
    public details: string | null;
    public url: string | null;
    public status: number | null;

    constructor() {
        this.message = null;
        this.details = null;
        this.url = null;
        this.status = null;
    }
}