import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageCulture } from '../autogenerated/model.autogenerated';
import { LanguageCultureService } from '../services/language-culture.service';

@Injectable()
export class LanguageCultureInterceptor implements HttpInterceptor {

  constructor(private readonly _languageCulture: LanguageCultureService) {
    // private readonly _language: LanguageService
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return <any>next.handle(this.applyLanguageCulture(request, this._languageCulture.currentLanguage))
  }

  private applyLanguageCulture(request: HttpRequest<any>, languageCulture: LanguageCulture | null) {
    if (languageCulture == null)
      return request;

    return request.clone({
      setHeaders: {
        'Accept-Language': `${languageCulture.languageCultureName}`
      }
    });
  }
}