import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'dialog-change-page-confirm',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './dialog-change-page-confirm.component.html',
  styleUrls: ['./dialog-change-page-confirm.component.scss']
})
export class DialogChangePageConfirmComponent implements AfterViewInit {

  @ViewChild('btnNo') btnNo!: ElementRef;

  promptText: string = '';

  constructor(
    private readonly _ref: DynamicDialogRef,
    private readonly _translate: TranslateService
  ) {
    this.promptText = _translate.instant('Common.Deactivate');
  }

  ngAfterViewInit(): void {
    //experimental
    setTimeout(() => {
      this.btnNo.nativeElement.blur();
    }, 10);
  }

  onNoClicked() {
    this._ref.close(false);
  }

  onConfirmClicked() {
    this._ref.close(true);
  }
}
