<div class="flex items-center">
    <i class="pi pi-info-circle mr-4 !text-3xl"></i>
    <span class="p-confirm-dialog-message">Do you want to delete this record?</span>
</div>

<div class="flex justify-end mt-6">
    <div class="mr-6">
        <button #btnNo pButton icon="pi pi-times" class="p-button-text" severity="secondary" label="No" (click)="onNoClicked()"></button>
    </div>
    <div class="">
        <p-button icon="pi pi-check" severity="danger" label="Elimina" (click)="onConfirmClicked()"></p-button>
    </div>
</div>