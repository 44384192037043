import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { httpOptions, httpOptionsMultipart } from 'src/app/core/utils/http-options';
import * as Models from './model.autogenerated';

export abstract class ApiAutogeneratedService {
  constructor(
    public _http: HttpClient,
    public _baseUrl: string,
  ) { }

  protected abstract _momentToString(moment: moment.Moment): string;
  protected abstract _handleRequest<T>(request: T): T;
  protected abstract _handleMultipart<T>(request: T): FormData;
  protected abstract _handleResponse<T>(response: T): T;
  protected abstract _handleError(error: any, obs: any): Observable<never>;


  public Airport_Read(idAirport?: number): Observable<Models.Airport> {
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    return this._http.get<Models.Airport>(`${this._baseUrl}/api/v1/Airport/Read?idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_List(request: Models.AirportListQuery): Observable<Models.AirportListQueryResponse> {
    let requestParam: string = request != null && request != undefined ? encodeURIComponent('' + request) : '';
    return this._http.get<Models.AirportListQueryResponse>(`${this._baseUrl}/api/v1/Airport/List?request=${requestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_Create(request: Models.AirportCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Airport/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_Update(request: Models.AirportUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Airport/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_Delete(idAirport: number): Observable<Models.Result> {
    let idAirportParam: string = encodeURIComponent('' + idAirport);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/Airport/Delete?idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_ListForDropDown(): Observable<Models.AirportListForDropDownQueryResponse> {
    return this._http.get<Models.AirportListForDropDownQueryResponse>(`${this._baseUrl}/api/v1/Airport/ListForDropDown`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_Token(request: Models.AuthTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/Token`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_RefreshToken(request: Models.AuthRefreshTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/RefreshToken`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_GenerateResetPasswordCode(usernameOrEmail?: string): Observable<Models.Result> {
    let usernameOrEmailParam: string = usernameOrEmail != null && usernameOrEmail != undefined ? encodeURIComponent('' + usernameOrEmail) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Auth/GenerateResetPasswordCode?usernameOrEmail=${usernameOrEmailParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_ResetPassword(request: Models.AuthResetPasswordCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Auth/ResetPassword`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_VerifyResetPasswordCode(request: Models.AuthVerifyResetPasswordCodeCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Auth/VerifyResetPasswordCode`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ChannelManager_Read(idChannelManager?: number): Observable<Models.ChannelManagerItem> {
    let idChannelManagerParam: string = idChannelManager != null && idChannelManager != undefined ? encodeURIComponent('' + idChannelManager) : '';
    return this._http.get<Models.ChannelManagerItem>(`${this._baseUrl}/api/v1/ChannelManager/Read?idChannelManager=${idChannelManagerParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ChannelManager_List(): Observable<Models.ChannelManagerListResponse> {
    return this._http.get<Models.ChannelManagerListResponse>(`${this._baseUrl}/api/v1/ChannelManager/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ChannelManager_Create(request: Models.ChannelManagerCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ChannelManager/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ChannelManager_Update(request: Models.ChannelManagerUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/ChannelManager/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ChannelManager_Delete(idChannelManager?: number): Observable<Models.Result> {
    let idChannelManagerParam: string = idChannelManager != null && idChannelManager != undefined ? encodeURIComponent('' + idChannelManager) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ChannelManager/Delete?idChannelManager=${idChannelManagerParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ChannelManager_ListForDropDown(): Observable<Models.ChannelManagerListForDropDownResponse> {
    return this._http.get<Models.ChannelManagerListForDropDownResponse>(`${this._baseUrl}/api/v1/ChannelManager/ListForDropDown`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public City_List(): Observable<Models.CityListQueryResponse> {
    return this._http.get<Models.CityListQueryResponse>(`${this._baseUrl}/api/v1/City/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public City_ListForDropDown(): Observable<Models.CityListForDropDownResponse> {
    return this._http.get<Models.CityListForDropDownResponse>(`${this._baseUrl}/api/v1/City/ListForDropDown`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommercialManagement_Read(idCommercialManagement?: number): Observable<Models.CommercialManagement> {
    let idCommercialManagementParam: string = idCommercialManagement != null && idCommercialManagement != undefined ? encodeURIComponent('' + idCommercialManagement) : '';
    return this._http.get<Models.CommercialManagement>(`${this._baseUrl}/api/v1/CommercialManagement/Read?idCommercialManagement=${idCommercialManagementParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommercialManagement_List(request: Models.CommercialManagementListQuery): Observable<Models.CommercialManagementListResponse> {
    let requestParam: string = request != null && request != undefined ? encodeURIComponent('' + request) : '';
    return this._http.get<Models.CommercialManagementListResponse>(`${this._baseUrl}/api/v1/CommercialManagement/List?request=${requestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommercialManagement_Create(request: Models.CommercialManagementCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/CommercialManagement/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommercialManagement_Update(request: Models.CommercialManagementUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/CommercialManagement/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommercialManagement_Delete(idCommercialManagement?: number): Observable<Models.Result> {
    let idCommercialManagementParam: string = idCommercialManagement != null && idCommercialManagement != undefined ? encodeURIComponent('' + idCommercialManagement) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/CommercialManagement/Delete?idCommercialManagement=${idCommercialManagementParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommercialManagement_ListForDropDown(): Observable<Models.CommercialManagementListForDropDownResponse> {
    return this._http.get<Models.CommercialManagementListForDropDownResponse>(`${this._baseUrl}/api/v1/CommercialManagement/ListForDropDown`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Communication_Read(idCommunication?: number): Observable<Models.CommunicationItem> {
    let idCommunicationParam: string = idCommunication != null && idCommunication != undefined ? encodeURIComponent('' + idCommunication) : '';
    return this._http.get<Models.CommunicationItem>(`${this._baseUrl}/api/v1/Communication/Read?idCommunication=${idCommunicationParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Communication_List(idHotel?: number, idCommercialManagement?: number): Observable<Models.CommunicationListResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    let idCommercialManagementParam: string = idCommercialManagement != null && idCommercialManagement != undefined ? encodeURIComponent('' + idCommercialManagement) : '';
    return this._http.get<Models.CommunicationListResponse>(`${this._baseUrl}/api/v1/Communication/List?idHotel=${idHotelParam}&idCommercialManagement=${idCommercialManagementParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Communication_Create(request: Models.CommunicationCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Communication/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Communication_Update(request: Models.CommunicationUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Communication/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Communication_Delete(idCommunication?: number): Observable<Models.Result> {
    let idCommunicationParam: string = idCommunication != null && idCommunication != undefined ? encodeURIComponent('' + idCommunication) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/Communication/Delete?idCommunication=${idCommunicationParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommunicationAttachment_Read(idCommunicationAttachment?: number): Observable<Models.CommunicationAttachment> {
    let idCommunicationAttachmentParam: string = idCommunicationAttachment != null && idCommunicationAttachment != undefined ? encodeURIComponent('' + idCommunicationAttachment) : '';
    return this._http.get<Models.CommunicationAttachment>(`${this._baseUrl}/api/v1/CommunicationAttachment/Read?idCommunicationAttachment=${idCommunicationAttachmentParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommunicationAttachment_List(idCommunication?: number): Observable<Models.CommunicationAttachmentListQueryResponse> {
    let idCommunicationParam: string = idCommunication != null && idCommunication != undefined ? encodeURIComponent('' + idCommunication) : '';
    return this._http.get<Models.CommunicationAttachmentListQueryResponse>(`${this._baseUrl}/api/v1/CommunicationAttachment/List?idCommunication=${idCommunicationParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommunicationAttachment_Create(request: Models.CommunicationAttachment_Create): Observable<number> {
    const wrappedRequest = this._handleMultipart(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/CommunicationAttachment/Create`, wrappedRequest, httpOptionsMultipart)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CommunicationAttachment_Delete(request: Models.CommunicationAttachmentDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/CommunicationAttachment/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Contract_Read(idContract?: number): Observable<Models.ContractItem> {
    let idContractParam: string = idContract != null && idContract != undefined ? encodeURIComponent('' + idContract) : '';
    return this._http.get<Models.ContractItem>(`${this._baseUrl}/api/v1/Contract/Read?idContract=${idContractParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Contract_List(idHotel: number): Observable<Models.ContractListResponse> {
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    return this._http.get<Models.ContractListResponse>(`${this._baseUrl}/api/v1/Contract/List?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Contract_Create(request: Models.ContractCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Contract/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Contract_Update(request: Models.ContractUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Contract/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Contract_Delete(idContract?: number): Observable<Models.Result> {
    let idContractParam: string = idContract != null && idContract != undefined ? encodeURIComponent('' + idContract) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/Contract/Delete?idContract=${idContractParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractAttachment_Read(idContractAttachment?: number): Observable<Models.ContractAttachment> {
    let idContractAttachmentParam: string = idContractAttachment != null && idContractAttachment != undefined ? encodeURIComponent('' + idContractAttachment) : '';
    return this._http.get<Models.ContractAttachment>(`${this._baseUrl}/api/v1/ContractAttachment/Read?idContractAttachment=${idContractAttachmentParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractAttachment_List(idContract?: number): Observable<Models.ContractAttachmentListQueryResponse> {
    let idContractParam: string = idContract != null && idContract != undefined ? encodeURIComponent('' + idContract) : '';
    return this._http.get<Models.ContractAttachmentListQueryResponse>(`${this._baseUrl}/api/v1/ContractAttachment/List?idContract=${idContractParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractAttachment_Create(request: Models.ContractAttachment_Create): Observable<number> {
    const wrappedRequest = this._handleMultipart(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ContractAttachment/Create`, wrappedRequest, httpOptionsMultipart)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractAttachment_Delete(request: Models.ContractAttachmentDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/ContractAttachment/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractPlanRate_Read(idContract: number): Observable<Models.ContractPlanRateReadQueryResponse> {
    let idContractParam: string = encodeURIComponent('' + idContract);
    return this._http.get<Models.ContractPlanRateReadQueryResponse>(`${this._baseUrl}/api/v1/ContractPlanRate/Read?idContract=${idContractParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractPlanRate_Save(request: Models.ContractPlanRateSaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ContractPlanRate/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractService_List(idContract: number): Observable<Models.ContractServiceListResponse> {
    let idContractParam: string = encodeURIComponent('' + idContract);
    return this._http.get<Models.ContractServiceListResponse>(`${this._baseUrl}/api/v1/ContractService/List?idContract=${idContractParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractService_Save(request: Models.ContractServiceSaveCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/ContractService/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Enum_List(): Observable<Models.EnumListQueryResponse> {
    return this._http.get<Models.EnumListQueryResponse>(`${this._baseUrl}/api/v1/Enum/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_Read(idHotel: number): Observable<Models.Hotel> {
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    return this._http.get<Models.Hotel>(`${this._baseUrl}/api/v1/Hotel/Read?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_List(request: Models.HotelListQuery): Observable<Models.HotelListResponse> {
    let requestParam: string = request != null && request != undefined ? encodeURIComponent('' + request) : '';
    return this._http.get<Models.HotelListResponse>(`${this._baseUrl}/api/v1/Hotel/List?request=${requestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_Create(request: Models.HotelCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Hotel/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_Update(request: Models.HotelUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Hotel/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_Delete(idHotel: number): Observable<Models.Result> {
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/Hotel/Delete?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_SendHotelCommunication(request: Models.HotelSendHotelCommunicationCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Hotel/SendHotelCommunication`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_ExcelExport(): Observable<Models.HotelExcelExportResponse> {
    return this._http.get<Models.HotelExcelExportResponse>(`${this._baseUrl}/api/v1/Hotel/ExcelExport`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelBookingContact_List(idHotel: number): Observable<Models.HotelBookingContactListResponse> {
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    return this._http.get<Models.HotelBookingContactListResponse>(`${this._baseUrl}/api/v1/HotelBookingContact/List?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelBookingContact_Save(request: Models.HotelBookingContactSaveCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelBookingContact/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChain_Read(idHotelChain: number): Observable<Models.HotelChain> {
    let idHotelChainParam: string = encodeURIComponent('' + idHotelChain);
    return this._http.get<Models.HotelChain>(`${this._baseUrl}/api/v1/HotelChain/Read?idHotelChain=${idHotelChainParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChain_List(): Observable<Models.HotelChainListResponse> {
    return this._http.get<Models.HotelChainListResponse>(`${this._baseUrl}/api/v1/HotelChain/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChain_Create(request: Models.HotelChainCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelChain/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChain_Update(request: Models.HotelChainUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelChain/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChain_Delete(idHotelChain: number): Observable<Models.Result> {
    let idHotelChainParam: string = encodeURIComponent('' + idHotelChain);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/HotelChain/Delete?idHotelChain=${idHotelChainParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChain_ListForDropDown(): Observable<Models.HotelChainListForDropDownResponse> {
    return this._http.get<Models.HotelChainListForDropDownResponse>(`${this._baseUrl}/api/v1/HotelChain/ListForDropDown`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelIropContact_List(idHotel: number): Observable<Models.HotelIropContactListResponse> {
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    return this._http.get<Models.HotelIropContactListResponse>(`${this._baseUrl}/api/v1/HotelIropContact/List?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelIropContact_Save(request: Models.HotelIropContactSaveCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelIropContact/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public LanguageCulture_List(): Observable<Models.LanguageCultureListQueryResponse> {
    return this._http.get<Models.LanguageCultureListQueryResponse>(`${this._baseUrl}/api/v1/LanguageCulture/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ownership_Read(idHotel?: number, idCommercialManagement?: number): Observable<Models.OwnershipItem> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    let idCommercialManagementParam: string = idCommercialManagement != null && idCommercialManagement != undefined ? encodeURIComponent('' + idCommercialManagement) : '';
    return this._http.get<Models.OwnershipItem>(`${this._baseUrl}/api/v1/Ownership/Read?idHotel=${idHotelParam}&idCommercialManagement=${idCommercialManagementParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ownership_Create(request: Models.OwnershipCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Ownership/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ownership_Update(request: Models.OwnershipUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ownership/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Representative_Read(idRepresentative?: number): Observable<Models.RepresentativeItem> {
    let idRepresentativeParam: string = idRepresentative != null && idRepresentative != undefined ? encodeURIComponent('' + idRepresentative) : '';
    return this._http.get<Models.RepresentativeItem>(`${this._baseUrl}/api/v1/Representative/Read?idRepresentative=${idRepresentativeParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Representative_List(idHotel?: number, idCommercialManagement?: number): Observable<Models.RepresentativeListResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    let idCommercialManagementParam: string = idCommercialManagement != null && idCommercialManagement != undefined ? encodeURIComponent('' + idCommercialManagement) : '';
    return this._http.get<Models.RepresentativeListResponse>(`${this._baseUrl}/api/v1/Representative/List?idHotel=${idHotelParam}&idCommercialManagement=${idCommercialManagementParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Representative_Create(request: Models.RepresentativeCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Representative/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Representative_Update(request: Models.RepresentativeUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Representative/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Representative_Delete(idRepresentative?: number): Observable<Models.Result> {
    let idRepresentativeParam: string = idRepresentative != null && idRepresentative != undefined ? encodeURIComponent('' + idRepresentative) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Representative/Delete?idRepresentative=${idRepresentativeParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TimeZone_Read(idTimeZone?: number): Observable<Models.TimeZoneItem> {
    let idTimeZoneParam: string = idTimeZone != null && idTimeZone != undefined ? encodeURIComponent('' + idTimeZone) : '';
    return this._http.get<Models.TimeZoneItem>(`${this._baseUrl}/api/v1/TimeZone/Read?idTimeZone=${idTimeZoneParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TimeZone_List(request: Models.TimeZoneListQuery): Observable<Models.TimeZoneListQueryResponse> {
    let requestParam: string = request != null && request != undefined ? encodeURIComponent('' + request) : '';
    return this._http.get<Models.TimeZoneListQueryResponse>(`${this._baseUrl}/api/v1/TimeZone/List?request=${requestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TimeZone_ListForDropDown(): Observable<Models.TimeZoneListForDropDownResponse> {
    return this._http.get<Models.TimeZoneListForDropDownResponse>(`${this._baseUrl}/api/v1/TimeZone/ListForDropDown`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Read(idUser?: number): Observable<Models.User> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.get<Models.User>(`${this._baseUrl}/api/v1/User/Read?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_List(): Observable<Models.UserListQueryResponse> {
    return this._http.get<Models.UserListQueryResponse>(`${this._baseUrl}/api/v1/User/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Create(request: Models.UserCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/User/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Update(request: Models.UserUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Delete(idUser: number): Observable<Models.Result> {
    let idUserParam: string = encodeURIComponent('' + idUser);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/User/Delete?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_UpdateLanguageCulture(idLanguageCulture?: number): Observable<Models.Result> {
    let idLanguageCultureParam: string = idLanguageCulture != null && idLanguageCulture != undefined ? encodeURIComponent('' + idLanguageCulture) : '';
    return this._http.get<Models.Result>(`${this._baseUrl}/api/v1/User/UpdateLanguageCulture?idLanguageCulture=${idLanguageCultureParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

}