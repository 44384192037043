import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { DialogMessageService } from "./services/dialog-message.service";
import { ResponseError } from "./models/response-error";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly _dialogMessage: DialogMessageService,
    private readonly _zone: NgZone,
  ) { }

  handleError(error: any) {
    let that = this;
    if (!(error instanceof ResponseError)
      && !(error instanceof HttpErrorResponse)) {
      if (error.message
        && (<string>error.message).startsWith('NG0100') //ExpressionChangedAfterItHasBeenCheckedError
      ) {
        //skip
      } else {
        this._zone.run(() =>
          that._dialogMessage.showError(error.rejection ?? error)
        );
      }
    }
    console.error('GlobalErrorHandler - ', error);
  }
}