import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DialogMessage } from '../../models/dialog-message';
import { DialogMessageType } from '../../models/dialog-message-type';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
//import { DialogMessage } from '../../models/dialog-message';
//import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
//import { DialogMessageType } from '../../models/dialog-message-type';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit {
  public message: DialogMessage;

  public messageTypeEnum = DialogMessageType;

  constructor(
    private readonly _config: DynamicDialogConfig,
    private readonly _ref: DynamicDialogRef
    ) {
    this.message = this._config.data;
  }

  ngOnInit(): void {
  }

  onOkClicked(){
    this._ref.close();
  }
}
