import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'dialog-delete-confirm',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  // providers: [ConfirmationService, MessageService],
  templateUrl: './dialog-delete-confirm.component.html',
  styleUrls: ['./dialog-delete-confirm.component.scss']
})
export class DialogDeleteConfirmComponent implements AfterViewInit {

  @ViewChild('btnNo') btnNo!: ElementRef;

  constructor(
    // private readonly _dialogDeleteConfirm: DialogDeleteConfirmService,
    private readonly _ref: DynamicDialogRef
  ) {

    // _dialogDeleteConfirm.subj$.subscribe(x=>{
    //   this.showConfirm();
    // })

  }
  ngAfterViewInit(): void {
    //experimental
    setTimeout(() => {
      this.btnNo.nativeElement.blur();
    }, 10);
  }

  onNoClicked() {
    this._ref.close(false);
  }

  onConfirmClicked() {
    this._ref.close(true);
  }
}
