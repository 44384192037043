<div class="flex items-center mt-6">
    {{errorMessage.message}}
</div>

<div class="mt-6" *ngIf="!loading">
    <p-accordion *ngIf="errorMessage.url != null || errorMessage.status != null || errorMessage.details != null">
        <p-accordionTab header="Details">
            <p *ngIf="errorMessage.url != null" [innerHTML]="'Url: ' + errorMessage.url"></p>
            <p *ngIf="errorMessage.status != null" [innerHTML]="'Status: ' + errorMessage.status"></p>
            <p *ngIf="errorMessage.details != null" class="break-words" [innerHTML]="'Details: ' + errorMessage.details"></p>
        </p-accordionTab>
    </p-accordion>
</div>

<div class="flex justify-end mt-6">
    <div class="">
        <p-button #btnOk icon="pi pi-check" label="Ok" [text]="true" (click)="onOkClicked()"></p-button>
    </div>
</div>