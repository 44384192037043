import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { LanguageApiService } from './core/services/language-api.service';
import { UserService } from './core/services/user.service';
import { EnumsService } from './core/services/enums.service';
import { Observable, exhaustMap, map } from 'rxjs';
import { MutexFastLockModule } from '@devlearning/mutex-fast-lock';
import { JwtAuthModule, StorageType } from '@devlearning/jwt-auth';
import { GlobalErrorHandler } from './core/global-error-handler';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AccordionModule } from 'primeng/accordion';
import { MessageComponent } from './core/components/message/message.component';
import { ToastModule } from 'primeng/toast';
import { LanguageCultureInterceptor } from './core/interceptors/language-culture.interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DialogLogoutConfirmComponent } from './core/components/dialog-logout-confirm/dialog-logout-confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    MessageComponent
  ],
  imports: [
    BrowserModule,
    AccordionModule,
    ConfirmDialogModule,
    ToastModule,
    DynamicDialogModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MutexFastLockModule.forRoot({
      lockPrefix: '_MUTEX_LOCK',
      timeout: 30000,
      debugEnabled: false
    }),
    JwtAuthModule.forRoot({
      logLevel: 1,
      tokenUrl: environment.TOKEN_URL,
      refreshUrl: environment.REFRESH_URL,
      useManualInitialization: true,
      storageType: StorageType.SESSION_STORAGE
    }),
    FontAwesomeModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [
        LanguageApiService,
        UserService,
        EnumsService
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageCultureInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initializeAppFactory(
  language: LanguageApiService,
  user: UserService,
  enums: EnumsService): () => Observable<any> {
  return () => user.initialize()
    .pipe(
      exhaustMap(x => language.initialize(x.userData?.languageCulture!)),
      exhaustMap(x => enums.initialize()),
      map(x => true)
    );
}
