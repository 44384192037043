import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtAuthService } from '@devlearning/jwt-auth';
import { BehaviorSubject, Subscription, forkJoin, of, throwError } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { User } from '../autogenerated/model.autogenerated';
import { JwtToken } from '../models/jwt-token';
import { ApiService } from './api.service';
import { DialogMessageService } from './dialog-message.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private _subscriptionAuth: Subscription | null = null;
  private _userSubject = new BehaviorSubject<User | null>(null);
  private _idUser: number | null = null;

  public get user$() { return this._userSubject.asObservable(); }
  public get user(): User | null { return this._userSubject.value; }

  //non injettare ENUM service
  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _api: ApiService,
    private readonly _jwtAuth: JwtAuthService<JwtToken>,
    _dialogMessage: DialogMessageService
  ) {
  }

  public initialize() {
    this._subscriptionAuth = this._jwtAuth.isLoggedIn$
    .pipe(
      filter(x => !x)
    )
    .subscribe(x => {
      this._idUser = null;
      this._userSubject.next(null);
    });
    
    return this._jwtAuth.init()
      .pipe(
        tap(x => {
          if (x != null && x.idUser != null)
            this._idUser = x.idUser;
        }),
        exhaustMap(x => forkJoin({
          userData: this._initializeUser()
        })),
      )
  }

  public isAuthenticated(): boolean {
    return this._jwtAuth.isLoggedIn;
  }

  public login(username: string, password: string) {
    return this._jwtAuth.token({ username: username, password: password })
      .pipe(
        exhaustMap(x => this.initialize()),
        map(x => this.user),
        catchError((err, obs) => {
          return throwError(() => new Error(err));
        })
      );
  }

  public logout() {
    this._jwtAuth.logout();
    this._idUser = null;
    this._userSubject.next(null);
    this._router.navigate(['login']);
    this._subscriptionAuth?.unsubscribe();
  }

  private _initializeUser() {
    if (this._idUser) {
      return this._api.User_Read(this._idUser)
        .pipe(
          tap(x => this._userSubject.next(x))
        )
    } else {
      return of<User | null>(null);
    }
  }
}
