import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { DialogErrorMessage } from '../../models/dialog-error-message';

@Component({
  selector: 'dialog-error',
  standalone: true,
  imports: [CommonModule, AccordionModule, ButtonModule],
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss']
})
export class DialogErrorComponent  implements AfterViewInit {

  @ViewChild('btnOk') btnOk!: ElementRef

  public loading: boolean = true;
  public errorMessage: DialogErrorMessage;

  constructor(
    private readonly _ref: DynamicDialogRef,
    private readonly _config: DynamicDialogConfig,
  ) {
    this.errorMessage = this._config.data;
  }

  ngAfterViewInit(): void {
    //experimental
    setTimeout(() => {
      this.btnOk?.nativeElement?.blur();
    }, 10);

    setTimeout(() => {
      this.loading = false;
    }, 10);
  }

  onOkClicked(){
    this._ref.close(true);
  }
}
